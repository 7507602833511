import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { json } from 'express';

const api_url: string = environment.url+'coupon/';

@Injectable({
  providedIn: 'root'
})
export class Service {

	constructor(private router:Router, private _http: Http) {  }

	getAll(){
		let headers = new Headers();
		  headers.append('Content-Type','application/json'); 
		  headers.append('device_type','3');
		  headers.append('session_token',localStorage.getItem('session_token'));
		  headers.append('email',localStorage.getItem('email')); 
		  let _url: string = api_url+'list';
		var usertype = localStorage.getItem('userType');
		var userId = localStorage.getItem('userId');
		var jsonData = {"usertype":usertype,"userId":userId}
	   // return this._http.get(_url, {headers:headers}).map(res => res.json());
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
	  }

	// get user by id
	getcoupon(_id){
		var headers = new Headers();
		headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'getcoupon/'+_id;
		
		return this._http.get(_url, {headers:headers}).map(res => res.json());
	}

	getedeliverydate(_id){
		var headers = new Headers();
		headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'getedeliverydate/'+_id;
		
		return this._http.get(_url, {headers:headers}).map(res => res.json());
	}
	// edit user by id
	update(jsonData,id){
		var headers = new Headers();
		// headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'update/coupon/'+id;
		// console.log('update article service '+jsonData.language);
		 return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
	}

	updatedeliverydate(jsonData,id){
		var headers = new Headers();
		// headers.append('Content-Type','application/json');
		headers.append('device_type','3');
		headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'updatedeliverydate/'+id;
		// console.log('update article service '+jsonData.language);
		 return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
	}

	add(jsonData){
		// console.log(jsonData);return
		var headers = new Headers();		
		// headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		// console.log(headers);
		let _url: string = api_url+'add';
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
	}

	deletes(id){
		// console.log('inside delete service'+id);
		var headers = new Headers();
		headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'delete';
		var jsonData = {"id":id}
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
	}
}