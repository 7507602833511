import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
//let config = require('config');
import {environment} from '../../../environments/environment';
const api_user: string = environment.url+'restaurants/';
var deviceType = '3';

@Injectable({
  providedIn: 'root'
})
export class RestaurantuserService {

  constructor(private router:Router, private _http: Http) {
   // console.log('restaurant user service');
    }

  // get Restaurant lists 

activeRestaurentList(id){	  
    let headers = new Headers();
    headers.append('Content-Type','application/json'); 
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
    let _url: string = api_user+'activateRestaurant';
    var jsonData = {"id":id}
     // return this._http.get(_url, {headers:headers}).map(res => res.json());
      return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());      
    }



  getInActiveRestaurantList(){
    let headers = new Headers();
      headers.append('Content-Type','application/json');
      headers.append('device_type', deviceType);
      headers.append('session_token',localStorage.getItem('session_token'));
      headers.append('email',localStorage.getItem('email')); 
    let _url: string = api_user+'inactiverestaurant';
    var usertype = localStorage.getItem('userType');
    var userId = localStorage.getItem('userId');
    var jsonData = {"usertype":usertype,"userId":userId}
   // return this._http.get(_url, {headers:headers}).map(res => res.json());
    return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }
  

  getRestaurantList(){
    let headers = new Headers();
      headers.append('Content-Type','application/json');
      headers.append('device_type', deviceType);
      headers.append('session_token',localStorage.getItem('session_token'));
      headers.append('email',localStorage.getItem('email')); 
    let _url: string = api_user+'allrestaurant';
    var usertype = localStorage.getItem('userType');
    var userId = localStorage.getItem('userId');
    var jsonData = {"usertype":usertype,"userId":userId}
   // return this._http.get(_url, {headers:headers}).map(res => res.json());
    return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }

  // Add Restaurant 

  addRestaurant(jsonData){

    console.log(jsonData);

    let headers = new Headers();
    //headers.append('Content-Type','application/json'); 
    console.log(deviceType);
    headers.append('device_type', '3');
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
    //console.log(jsonData);
    let _url: string = api_user+'addrestaurant';
    return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }

  // // update Restaurant 

	updateRestaurant(jsonData){		
	 //console.log('get Restaurant service '+jsonData);
		let headers = new Headers();
    //headers.append('Content-Type','application/json'); 
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
		let _url: string = api_user+'updaterestaurant';
		// console.log('update article service '+jsonData.language);
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }   

	updateQRCode(jsonData){
		let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
		let _url: string = api_user+'updateQRCode';
		// console.log('update qr code res service '+jsonData);return true;
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }   

	updateTableQRCode(jsonData){
		let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
		let _url: string = api_user+'updateTableQRCode';
		// console.log('update qr code res service '+jsonData);
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  } 

  updateLoyalityQRCode(jsonData){
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
    let _url: string = api_user+'updateLoyalityQRCode';
    // console.log('update qr code res service '+jsonData);return true;
    return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }   

	deleteQRCode(jsonData){
		let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
		let _url: string = api_user+'deleteQRCode';
		// console.log('update qr code res service '+jsonData);return true;
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }   

  // get Restaurant by id
	getRestaurant(_id){
		let headers = new Headers();
    headers.append('Content-Type','application/json'); 
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
    let _url: string = api_user+'getrestaurant/'+_id;
		return this._http.get(_url, {headers:headers}).map(res => res.json());
  }

  //Delete Restaurant by id
	deleteRestaurant(_id){
		let headers = new Headers();
    headers.append('Content-Type','application/json'); 
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
    let _url: string = api_user+'deleterestaurant/'+_id;
		return this._http.get(_url, {headers:headers}).map(res => res.json());
  }

   // get Restaurant Info by id
	restaurantInfo(_id){
		let headers = new Headers();
    headers.append('Content-Type','application/json'); 
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email')); 
    let _url: string = api_user+'infogetrestaurant/info/'+_id;
		return this._http.get(_url, {headers:headers}).map(res => res.json());
  }
  
  // update Restaurant info
  
	updateRestaurantInfo(jsonData){
		let headers = new Headers();
    //headers.append('Content-Type','application/json'); 
    headers.append('device_type', deviceType);
    headers.append('session_token', localStorage.getItem('session_token'));
    headers.append('email',localStorage.getItem('email'));    
		let _url: string = api_user+'updaterestaurantinfo';  
		// console.log('update user info service '+jsonData.res_image_title);
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
  }

 }
