import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { json } from 'express';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import 'rxjs/add/operator/catch';



//import {_throw} from 'rxjs/observable/throw';

import 'rxjs/add/operator/catch';

const api_url: string = environment.url+'section/';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

	constructor(private router:Router, private _http: Http, private http: HttpClient) {  }
	// get section lists
	getSectionList(){
		// console.log('in dish service localstorage data = '+localStorage.session_token);
		let headers = new Headers();
		// headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
    	headers.append('email',localStorage.email);
		let _url: string = api_url+'list';
		return this._http.get(_url, {headers:headers}).map(res => res.json());
	}

	// get Section by id
	getSection(_id){
		var headers = new Headers();
		// headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'getsection/'+_id;
		return this._http.get(_url, {headers:headers}).map(res => res.json());
	}

	// edit Section by id
	updateSection(jsonData,id){		
		// console.log('get article service '+jsonData);
		var headers = new Headers();
		headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'update/section/'+id;
		// console.log('update article service '+jsonData.language);
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
		/*return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json()).subscribe(
			data => data,
			err => err
		  );*/
	}

	addSection(jsonData){
		var headers = new Headers();
		headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		// console.log(headers);
		let _url: string = api_url+'add';
		return this._http.post(_url, jsonData, {headers:headers}).map(res => res.json());
	}

	deleteSection(id){
		// console.log('inside delete service'+id);
		var headers = new Headers();
		headers.append('Content-Type','application/json');
    	headers.append('device_type','3');
    	headers.append('session_token',localStorage.session_token);
		headers.append('email',localStorage.email);
		let _url: string = api_url+'delete';
		return this._http.post(_url, id, {headers:headers}).map(res => res.json());
	}
}